<template>
  <v-container fluid>
    <v-card class="pa-7">
      <v-card-text>
        <div>
          <template>
            <v-data-table
              :headers="sectionHeaders"
              :items="items"
              item-key="id"
              class="elevation-1"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>المحاور</v-toolbar-title>
                  <v-spacer></v-spacer>

                  <SectionForm
                    @closeDialog="openDialog = false"
                    @formSubmitted="formSubmitted"
                    :formTitle="formTitle"
                    :dialog="openDialog"
                    :record="record"
                    :id="id"
                    v-if="openDialog"
                  />
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    @click="dialogOpen(null, 'محور', $route.params.id)"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="headline"
                        >Are you sure you want to delete this
                        item?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItemConfirm()"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template
                v-slot:item.actions="{ item }"
                style="textAlign:right !important"
              >
                <!-- <v-btn to="posts/add" text class="pa-0"> -->
                <v-icon
                  color="primary"
                  small
                  class="mr-2"
                  @click="dialogOpen(item, 'محور', null)"
                >
                  mdi-pencil
                </v-icon>
                <!-- </v-btn> -->

                <v-icon
                  color="error"
                  small
                  class="mr-2"
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>

                <v-icon
                  color="error"
                  small
                  class="mr-2"
                  @click="goToLecture(item)"
                >
                  mdi-plus
                </v-icon>
              </template>
            </v-data-table>
          </template>
          <v-snackbar
            v-model="snackbar"
            absolute
            top
            :multi-line="true"
            :color="snackbarColor"
          >
            {{ snackbarMsg }}
            <template v-slot:action="{ attrs }">
              <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>
      </v-card-text>
    </v-card>

    <!-- snackbar -->
    <!-- <v-snackbar
      v-model="snackbar"
      absolute
      top
      :multi-line="true"
      :color="snackbarColor"
    >
      {{ snackbarMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar> -->
  </v-container>
</template>
<script>
import LectureForm from "./LectureForm";
import SectionForm from "./SectionForm";
import CourseForm from "./CourseForm";
import ChallengeForm from "./ChallengeForm";
import ApiService from "@/services/api.service";

export default {
  components: {
    LectureForm,
    SectionForm,
    CourseForm,
    ChallengeForm,
  },
  data: () => ({
    snackbar: false,
    snackbarMsg: null,
    snackbarColor: "success",
    loadingSection: true,
    id: null,
    openDialog0: false,
    openDialog: false,
    openDialog1: false,
    openDialog2: false,
    dialogDelete: false,
    formTitle: "Add New Item",
    record: null,
    items: [],
    singleExpand: false,
    singleExpand1: false,
    singleExpand2: false,
    singleExpand3: false,
    sectionHeaders: [
      {
        text: "المحور",
        align: "start",
        sortable: false,
        value: "title",
      },
      { text: "", value: "actions", sortable: false },
    ],
  }),
  methods: {
    goToLecture(item) {
      this.$router.push(`/lectures/add/${item.id}`);
    },
    async getCourses() {
      try {
        this.loadingSection = true;
        // &filter[section_id.lecture_id.challenge_id.parent_id][empty]
        const { data } = await ApiService.get(
          `items/sections?fields=id,title,entitle,duration&filter[course_id]=${this.$route.params.id}`
        );
        // ,lecture_id.id,lecture_id.title,lecture_id.discription,lecture_id.type,lecture_id.video_link,lecture_id.is_mandatory,lecture_id.duration,lecture_id.attachment,lecture_id.is_visible,lecture_id.challenge_id.id,lecture_id.challenge_id.title,lecture_id.challenge_id.order,lecture_id.challenge_id.answers.id,lecture_id.challenge_id.answers.title,lecture_id.challenge_id.answers.order,lecture_id.challenge_id.answers.is_answer,lecture_id.challenge_id.answers.why_is_answer,lecture_id.challenge_id.lecture_id
        this.items = data.data;
        console.log(this.items);
        this.loadingSection = false;
      } catch (error) {
        console.log(error);
      }
    },
    async loadData() {
      try {
        this.loading = true;
        // doce to load data ...
        this.getCourses();
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    dialogOpen(item, type, id) {
      this.record = item;
      this.id = id;
      if (item) {
        this.formTitle = `تعديل ${type}`;
      } else {
        this.formTitle = `إضافة ${type}`;
      }
      if (type == "course") {
        this.openDialog0 = true;
      } else if (type == "محور") {
        this.openDialog = true;
      } else if (type == "lecture") {
        this.openDialog1 = true;
      } else if (type == "challenge") {
        this.openDialog2 = true;
      }
    },
    formSubmitted(msg) {
      this.openDialog = false;
      this.openDialog1 = false;
      // this.openDialog2 = false;
      this.openDialog0 = false;
      this.snackbarMsg = msg;
      this.snackbar = true;
      this.loadData();
    },
    deleteItem(record) {
      this.record = record;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      try {
        this.loading = true;
        const { data } = await ApiService.delete(
          `items/sections/${this.record.id}`
        );
        this.loading = false;
        this.closeDelete();
        let msg;
        msg = "deleted successfully";
        // }
        alert(msg);
        this.loadData();
      } catch (error) {
        console.log(error);
      }
    },

    closeDelete() {
      this.dialogDelete = false;
    },
  },
  mounted() {
    this.getCourses();
    console.log(this.$route.params.id);
  },
};
</script>
